<template>
  <div style="height: 100%;">
    <el-button @click="test">打印</el-button>
    <iframe id="myIframe" :src="url" frameborder="0">
      <link rel="stylesheet" type="text/css" href="print.css" media="print">
    </iframe>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      url:''
    };
  },
  created() {
    this.reportId = this.$route.query.id
    this.url = 'https://www.apesk.com/mensa/common_report_getid/eq_report_admin.asp?id=' + this.reportId
    const head = document.getElementById("footer");
    head.style.display = "none";
    const LuHead = document.getElementById("LuHead");
    LuHead.style.display = "none";
  },
  watch: {
  },
  methods: {
    test() {
      const iframe = document.getElementById('myIframe');
      console.log(iframe.contentDocument)

      if (iframe && iframe.contentDocument) {
        console.log('12312')
        const newWin = window.open(); // 打开新窗口
        newWin.document.write(iframe.contentDocument.documentElement.outerHTML); // 将内容写入新窗口的文档中
        newWin.document.close(); // 关闭文档流，完成写入操作
        newWin.print(); // 在新窗口中打印内容
      }
    }
  },
};
</script>

<style scoped lang='less'>
iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
